import React, { ReactElement } from "react";
import Head from "next/head";
import styled from "styled-components";
import { Timer } from "@/components";
import Image from "next/image";
import { useRouter } from "next/router";
import { getSalonId } from "@/utils";
import { useQuery } from "@tanstack/react-query";
import { SalonRatingSettingService } from "@/services/SalonRatingSettingService";
import { useStore } from "@/store";

export interface RaitingLayoutProps {
  title?: string;
  children?: ReactElement | JSX.Element | undefined;
  headerTitle?: string;
  footerButton?: JSX.Element | ReactElement;
  isFooterCustom?: boolean;
}

const StyledLayoutWrapper = styled.div``;

export function RaitingLayout(props: RaitingLayoutProps) {
  const { title = "Đánh giá", children, headerTitle, footerButton, isFooterCustom } = props;
  const [state, dispatch] = useStore();
  const {salonRatingSetting} = state;

  const feedbackNotice = salonRatingSetting?.find(item => item.key === "feedback_notice")?.value;
  const hotline = salonRatingSetting?.find(item => item.key === "hotline")?.value;
  const router = useRouter();

  const renderFooterCustom = () => {
    return (
      <div className="flex items-center px-[52px] justify-between">
        <div className="w-[534px]">
          <p className="text-[20px] text-justify">
            {feedbackNotice} {hotline}
          </p>
        </div>
        <div className="p-[20px]">{footerButton}</div>
      </div>
    )
  }
  const isCustom = isFooterCustom && feedbackNotice;
  const renderFooter = isCustom ? renderFooterCustom() : footerButton;

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="shortcut icon" href="/statics/Logo-easysalon.png" />
      </Head>
      <StyledLayoutWrapper>
        <div className="h-[120px] relative flex justify-between items-center px-[52px]">
          <Image
            src="/statics/icons/arrow-left.svg"
            alt="icon-img"
            width={48}
            height={48}
            className="absolute cursor-pointer"
            onClick={() => {
              router.back();
            }}
          />
          <h2 className="text-center mb-0">{headerTitle}</h2>
          <span></span>
        </div>
        <div>{children}</div>
        <div className="w-full border-t-[3px] border-solid border-[#E5E5E5] py-[24px] bg-white fixed bottom-0 text-center">
          {renderFooter}
        </div>
      </StyledLayoutWrapper>
    </>
  );
}
